<template>
  <div class="apartment-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >
      <b-col
        cols="12"
        sm="4"
      >
        <card-statistic
          color="secondary"
          icon="HomeIcon"
          :value="analytics.total"
          title="Tòa nhà"
        />
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <card-statistic
          icon="HomeIcon"
          :value="analytics.active"
          title="Hoạt động"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <card-statistic
          icon="HomeIcon"
          color="secondary"
          :value="analytics.inactive"
          title="Không hoạt động"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Tòa nhà</b-card-title>
        <crud-buttons
          name="Tòa nhà"
          modal="modal-apartment"
          :selected-rows="selectedRows"
          :show-import="$can('create', 'apartment')"
          :show-export="$can('read', 'apartment')"
          link-template-file="https://cdn.resident.vn/static/bang_nhap_lieu_toa_nha.xlsx"
          :enable-add="$can('create', 'apartment')"
          :hide-delete="!$can('delete', 'apartment')"
          @on-delete="onDelete"
          @on-add="resetItem"
          @on-import-file="importData"
          @on-export="exportData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            v-if="isEnableLocationModule()"
            md="4"
          >
            <select-location
              v-model="location"
              :label="null"
            />
          </b-col>
          <b-col :md="isEnableLocationModule() ? 4 : 6">
            <select-active-status v-model="active" />
          </b-col>
          <b-col :md="isEnableLocationModule() ? 4 : 6">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm Tòa nhà..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-apartment-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <span v-else-if="props.column.field === 'active'">
                  <b-badge
                    :variant="props.row.active ? 'light-primary' : 'light-danger'"
                    class="font-small-1"
                  >
                    <span>{{ props.row.active ? "Có" : "Không" }}</span>
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Tòa nhà"
                    modal="modal-apartment"
                    :hide-edit="!$can('update', 'apartment')"
                    :hide-delete="!$can('delete', 'apartment')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <apartment-modal
      :apartment-id="item ? item.id : null"
      :item="item"
      @refetch-data="fetchData"
    />
    <apartment-detail-modal :apartment-id="item.id" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
} from "bootstrap-vue";

import SelectActiveStatus from "@/views/components/SelectActiveStatus.vue";
import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import CardStatistic from "@/views/components/CardStatistic.vue";
// eslint-disable-next-line import/no-cycle
import SelectLocation from '@/views/components/SelectLocation.vue';
import { isEnableLocationModule } from '@/auth/utils';
import useApartmentList from "./useApartmentList";
import ApartmentModal from "../modal/ApartmentModal.vue";
import ApartmentDetailModal from "../detail/ApartmentDetailModal.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    TableActions,
    CrudButtons,
    SelectLocation,
    SelectActiveStatus,
    VueGoodTable,
    ApartmentModal,
    CardStatistic,
    ApartmentDetailModal,
  },

  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      active,
      location,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchApartments,
      deleteApartments,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
    } = useApartmentList();

    return {
      analytics,
      item,
      columns,
      rows,
      active,
      location,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchApartments,
      deleteApartments,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      isEnableLocationModule,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(apartment) {
      const deleteObjects = apartment && apartment.id > 0 ? [apartment] : this.selectedRows;
      this.deleteApartments(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
@media (max-width: 960px) {
  .tbl-address {
    width: 400px !important;
    min-width: 400px !important;
  }
}

// .apartment-list-container {
//   .vgt-table {
//       thead {
//         tr {
//           th {
//             min-width: 100px !important;

//             &:first-child {
//               min-width: 50px !important;
//             }
//             &:nth-child(2) {
//                min-width: 80px !important;
//             }
//             &:nth-child(3) {
//                min-width: 80px !important;
//             }
//             &:nth-child(4) {
//                min-width: 180px !important;
//             }
//             &:nth-child(6) {
//                min-width: 300px !important;
//             }
//           }
//         }
//       }
//     }
// }
</style>
